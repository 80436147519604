<template>
  <div>
    <b-sidebar
      id="sidebar-bill-details"
      sidebar-class="sidebar-lg"
      :visible="billDetailsSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateBillDetailsSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Detalhes da solicitação</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="m-2" v-if="bill">
          <template v-if="!isExpense">
            <b-row>
              <b-col cols="6" class="mb-1"> Tipo contrato: </b-col>
              <b-col cols="6" class="mb-1">
                <span v-if="bill.user && bill.user.department_id">
                  Colaborador
                </span>
                <span v-else> 
                  PN 
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" class="mb-1"> Solicitante: </b-col>
              <b-col cols="6" class="mb-1 text-primary">
                <b> {{ bill.consultant.name }} </b>
              </b-col>
            </b-row>
          </template>
          <b-row>
            <b-col cols="6" class="mb-1"> Status da solicitação: </b-col>
            <b-col cols="6" class="mb-1">
              {{ bill.status | withdrawalStatus }}
            </b-col>
            <b-col cols="6" class="mb-1"> Data da solicitação: </b-col>
            <b-col cols="6" class="mb-1">
              {{ bill.created_at | onlyDate }}
            </b-col>
            <b-col cols="6" class="mb-1"> Data do pagamento: </b-col>
            <b-col cols="6" class="mb-1">
              {{ bill.withdrawal ? bill.withdrawal.created_at : "" | dateTime }}
            </b-col>
            <b-col cols="6" class="mb-1"> Data de faturamento: </b-col>
            <b-col cols="6" class="mb-1">
              {{
                bill.withdrawal ? bill.withdrawal.billing_date : "" | dateTime
              }}
            </b-col>
            <b-col cols="6" class="mb-1" style="font-weight: bold"> Valor: </b-col>
            <b-col cols="6" class="mb-1 text-primary" >
              {{ bill.amount | toCurrency }}
            </b-col>
          </b-row>
          <template v-if="!isExpense">
            <b-row class="border-bottom border-top">
              <b-col cols="12" class="mb-1 mt-1">
                <b>Detalhes da fatura</b>
              </b-col>
              <b-col cols="6" class="mb-1"> Total de entrevista paga: </b-col>
              <b-col cols="6" class="mb-1">
                <span>
                  {{ bill.interviews_amount | toCurrency }}
                </span>
              </b-col>
              <b-col cols="6" class="mb-1"> Total de assinatura: </b-col>
              <b-col cols="6" class="mb-1">
                <span>
                  {{ bill.subscriptions_amount | toCurrency }}
                </span>
              </b-col>
              <b-col cols="6" class="mb-2" style="font-weight: bold"> Total plataforma Futuro: </b-col>
              <b-col cols="6" class="mb-2">
                <span>
                  {{ bill.subscriptions_amount + bill.interviews_amount | toCurrency }}
                </span>
              </b-col>
              <b-col cols="6" class="mb-1">
                Total de solução financeira:
              </b-col>
              <b-col cols="6" class="mb-1">
                <span>
                  {{ bill.financial_solutions_amount | toCurrency }}
                </span>
              </b-col>
              <b-col cols="6" class="mb-1"> Total de ajuste: </b-col>
              <b-col cols="6" class="mb-1">
                <span>
                  {{ bill.adjustments_amount | toCurrency }}
                </span>
              </b-col>
              <b-col cols="6" class="mb-2" style="font-weight: bold"> Total Futuro Hub: </b-col>
              <b-col cols="6" class="mb-2">
                <span>
                  {{ bill.adjustments_amount + bill.financial_solutions_amount | toCurrency }}
                </span>
              </b-col>

              <b-col cols="6" class="mb-1" style="font-weight: bold"> Total da fatura: </b-col>
              <b-col cols="6" class="mb-1">
                <span>
                  {{ invoiceTotal | toCurrency }}
                </span>
              </b-col>
            </b-row>
          </template>
          <template v-if="expenses && expenses.length > 0">
            <b-row class="border-bottom border-top">
              <template v-for="expense in expenses">
                <b-col cols="6" class="mb-1 mt-1">
                  <b>Despesa:</b>
                </b-col>
                <b-col cols="6" class="mb-1 mt-1"> {{ expense.name }} </b-col>
                <b-col cols="6" class="mb-1">Centro de custo:</b-col>
                <b-col cols="6" class="mb-1">{{ expense.cost_center.name }} </b-col>
                <b-col cols="6" class="mb-1">Categoria:</b-col>
                <b-col cols="6" class="mb-1">{{ expense.cost_center_category.name }} </b-col>
                <b-col cols="6" class="mb-1">Valor:</b-col>
                <b-col 
                  cols="6"
                  class="mb-1 text-primary"
                  style="font-weight: bold"
                >{{ expense.amount | toCurrency }} </b-col>
              </template>
            </b-row>
          </template>
          <b-row class="mt-1">
            <template v-if="isExpense">
              <b-col cols="6" class="mb-1"> Método de pagamento: </b-col>
              <b-col cols="6" class="mb-1"> {{ expenses[0].payment_type.name }} </b-col>
              <b-col cols="6" class="mb-1"> Chave de pagamento: </b-col>
              <b-col cols="6" class="mb-1" style="overflow-wrap: break-word"> 
                {{ expenses[0].payment_key }} 
              </b-col>
            </template>
            <template v-else>
              <b-col cols="6" class="mb-1"> Chave Pix: </b-col>
              <b-col cols="6" class="mb-1" style="overflow-wrap: break-word">
                {{ bill.consultant.franchise_pix }}
              </b-col>
              <b-col cols="6" class="mb-1"> Tipo da chave: </b-col>
              <b-col cols="6" class="mb-1 text-capitalize">
                {{
                  bill.consultant.franchise_pix
                    ? bill.consultant.franchise_pix_type_key
                    : ""
                }}
              </b-col>
            </template>
            <b-col cols="12" class="mb-1">
              <b-button
                v-if="bill.document_url"
                variant="link"
                size="md"
                no-caret
                style="padding: 0"
                @click="download(bill.id)"
              >
                Ver nota fiscal <feather-icon icon="DownloadIcon" />
              </b-button>
            </b-col>
            <b-col cols="6" class="mb-1"> Comentário: </b-col>
            <b-col cols="6" class="mb-1">
              <span v-html="bill.comment"></span>
            </b-col>
          </b-row>
        </div>
        <div v-else class="d-flex justify-content-center m-2">
          <b-spinner variant="primary" />
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import * as types from "../store/types";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bill: undefined,
      expenses: undefined,
    };
  },
  setup() {
    return { toast: useToast() };
  },
  computed: {
    ...mapGetters({
      billDetailsSidebar: types.BILL_DETAILS_SIDEBAR,
    }),
    invoiceTotal: function () {
      return (
        this.bill.subscriptions_amount +
        this.bill.interviews_amount +
        this.bill.financial_solutions_amount +
        this.bill.adjustments_amount
      );
    },
    isEmployee: function () {
      return this.bill && 
        this.bill.user && 
        this.bill.user.department_id
    },
    isExpense: function () {
      return this.expenses &&
        this.expenses.length > 0 &&
        !this.isEmployee
    }
  },
  methods: {
    ...mapMutations({
      mutateBillDetailsSidebar: types.MUTATE_BILL_DETAILS_SIDEBAR,
    }),
    ...mapActions({
      getBillDetails: types.GET_BILL_DETAILS,
      downloadDocument: types.DOWNLOAD_DOCUMENT,
    }),
    onShow() {
      this.getBillDetails(this.billDetailsSidebar.id)
        .then((response) => {
          if (response.data.bill.id) {
            this.bill = response.data.bill;
            this.expenses = response.data.expenses;
          } else {
            throw new DOMException("Report não encontrado.");
          }
        })
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os detalhes. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.hide;
        });
    },
    download(id) {
      this.loading = true;
      this.downloadDocument(id)
        .then((response) => {
          setTimeout(() => {
            window.open(response.data, "_blank").focus();
          });
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: `Ocorreu um erro ao fazer download. Entre em contato com o setor de TI.`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clear() {
      this.bill = undefined;
    },
  },
};
</script>

<style lang="scss">
.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
</style>
