<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2"></div>
      <b-table
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontrados resultados para esta busca"
        :busy="loading.table"
      >
        <template #thead-top="data">
          <b-tr>
            <b-th colspan="1" class="header-spacing"></b-th>
            <b-th
              colspan="4"
              class="header-spacing text-center border-left bg-success bg-lighten-2 table-b-table-default"
            >
              <span>Entradas</span>
            </b-th>
            <b-th
              colspan="4"
              class="header-spacing text-center border-left border-right bg-danger bg-lighten-2 table-b-table-default"
            >
              <span>Saídas</span>
            </b-th>
            <b-th
              colspan="1"
              class="header-spacing text-center border-left border-right bg-info bg-lighten-2 table-b-table-default"
            >
              <span>Saldo</span>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th colspan="1" class="header-spacing"></b-th>
            <b-th
              class="header-spacing text-center border-left bg-success bg-lighten-1 table-b-table-default"
            >
              <span>
                {{ totals.ep_payments | toCurrency }}
              </span>
            </b-th>
            <b-th
              class="header-spacing text-center border-left bg-success bg-lighten-1 table-b-table-default"
            >
              <span>
                {{ totals.subscription_payments | toCurrency }}
              </span>
            </b-th>
            <b-th
              class="header-spacing text-center border-left bg-success bg-lighten-1 table-b-table-default"
            >
              <span>
                {{ totals.financial_solution_payments | toCurrency }}
              </span>
            </b-th>
            <b-th
              class="header-spacing text-center border-left bg-success bg-lighten-4 table-b-table-default"
            >
              <span>
                {{ totals.total_payments | toCurrency }}
              </span>
            </b-th>
            <b-th
              class="header-spacing text-center border-left bg-danger bg-lighten-1 table-b-table-default"
            >
              <span>
                {{ totals.provisioned_commissions | toCurrency }}
              </span>
            </b-th>
            <b-th
              class="header-spacing text-center border-left bg-danger bg-lighten-1 table-b-table-default"
            >
              <span>
                {{ totals.provisioned_employee | toCurrency }}
              </span>
            </b-th>
            <b-th
              class="header-spacing text-center border-left bg-danger bg-lighten-1 table-b-table-default"
            >
              <span>
                {{ totals.provisioned_expenses | toCurrency }}
              </span>
            </b-th>
            <b-th
              class="header-spacing text-center border-left bg-danger bg-lighten-4 table-b-table-default"
            >
              <span>
                {{ totals.provisioned_total | toCurrency }}
              </span>
            </b-th>
            <b-th
              class="header-spacing text-center border-left bg-info bg-lighten-1 table-b-table-default"
            >
              <span>
                {{ totals.balance | toCurrency }}
              </span>
            </b-th>
          </b-tr>
        </template>
        <!-- Column: Mês/ano -->
        <template #cell(date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.date | monthYearAbbrev
            }}</span>
          </div>
        </template>
        <!-- Column: EP-->
        <template #cell(ep_payments)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.ep_payments | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Assinaturas -->
        <template #cell(subscription_payments)="data">
          <div class="text-wrap">
            <span class="align-text-top">
              {{ data.item.subscription_payments | toCurrency }}
            </span>
          </div>
        </template>
        <!-- Column: Soluções  -->
        <template #cell(financial_solution_payments)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.financial_solution_payments | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Total previsto  -->
        <template #cell(total_payments)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.total_payments | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Previsto PNs -->
        <template #cell(provisioned_commissions)="data">
          <div class="text-wrap">
            <span class="align-text-top">
              {{ data.item.provisioned_commissions | toCurrency }}
            </span>
          </div>
        </template>
        <!-- Column: Previsto Colaboradores  -->
        <template #cell(provisioned_employee)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.provisioned_employee | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Previsto Despesas  -->
        <template #cell(provisioned_expenses)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.provisioned_expenses | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Total previsto -->
        <template #cell(provisioned_total)="data">
          <div class="text-wrap">
            <span class="align-text-top">
              {{ data.item.provisioned_total | toCurrency }}
            </span>
          </div>
        </template>
        <!-- Column: Saldo  -->
        <template #cell(balance)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.balance | toCurrency
            }}</span>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BSpinner,
  BTooltip,
  BButtonGroup,
  BFormGroup,
  BMedia,
  BMediaBody,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ExpectedBalanceChart from "../components/ExpectedBalanceChart";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as managmentTypes from "@/constants/financial_management_types";
import { getRangeDates } from "@/helpers/date_picker";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BTooltip,
    BButtonGroup,
    BFormGroup,
    BMedia,
    BMediaBody,
    BCardTitle,
    vSelect,
    flatPickr,
    ExpectedBalanceChart,
  },
  setup() {
    const { skin } = useAppConfig();
    const actualYear = new Date().getFullYear();
    return { toast: useToast(), skin, actualYear };
  },
  data() {
    return {
      managmentTypes,
      loading: {
        table: false,
        consultants: false,
        employees: false,
        withdrawalStatuses: false,
      },
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
        locale: Portuguese,
        minDate: new Date(),
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "Y-m",
            altFormat: "F Y",
            theme: this.skin,
          }),
        ],
      },
      tableColumns: [
        { key: "date", label: "Mês/ano", sortable: true },
        { key: "ep_payments", label: "EPs Previstas", sortable: true },
        {
          key: "subscription_payments",
          label: "Assinaturas Previstas",
          sortable: true,
        },
        {
          key: "financial_solution_payments",
          label: "Soluções Previstas",
          sortable: true,
        },
        { key: "total_payments", label: "Total", sortable: true },
        {
          key: "provisioned_commissions",
          label: "Previsto PNs",
          sortable: true,
        },
        {
          key: "provisioned_employee",
          label: "Previsto Colaboradores",
          sortable: true,
        },
        {
          key: "provisioned_expenses",
          label: "Previsto Despesas",
          sortable: true,
        },
        { key: "provisioned_total", label: "Total", sortable: true },
        { key: "balance", label: "Saldo", sortable: true },
      ],
      rangeDate: undefined,
      sortField: undefined,
    };
  },
  computed: {
    ...mapGetters({
      searchResult: types.EXPECTED_BILLS_SEARCH_RESULT,
    }),
    items: function () {
      if (this.searchResult) {
        return _(this.searchResult)
          .groupBy("date")
          .map((objs, key) => ({
            date: key,
            ep_payments: this.findAmount(objs, "ep_payments"),
            subscription_payments: this.findAmount(
              objs,
              "subscription_payments"
            ),
            financial_solution_payments: this.findAmount(
              objs,
              "financial_solution_payments"
            ),
            total_payments: this.findAmount(objs, "total_payments"),
            provisioned_commissions: this.findAmount(
              objs,
              "provisioned_commissions"
            ),
            provisioned_employee: this.findAmount(objs, "provisioned_employee"),
            provisioned_expenses: this.findAmount(objs, [
              "provisioned_expenses",
              "provisioned_adjustments",
            ]),
            provisioned_total: this.findAmount(objs, [
              "provisioned_commissions",
              "provisioned_employee",
              "provisioned_expenses",
              "provisioned_adjustments",
            ]),
            balance:
              this.findAmount(objs, "total_payments") -
              this.findAmount(objs, [
                "provisioned_commissions",
                "provisioned_employee",
                "provisioned_expenses",
                "provisioned_adjustments",
              ]),
          }))
          .value();
      }
      return [];
    },
    totals: function () {
      return {
        ep_payments: this.findAmount(this.items, "ep_payments"),
        subscription_payments: this.findAmount(
          this.items,
          "subscription_payments"
        ),
        financial_solution_payments: this.findAmount(
          this.items,
          "financial_solution_payments"
        ),
        total_payments: this.findAmount(this.items, "total_payments"),
        provisioned_commissions: this.findAmount(
          this.items,
          "provisioned_commissions"
        ),
        provisioned_employee: this.findAmount(
          this.items,
          "provisioned_employee"
        ),
        provisioned_expenses: this.findAmount(
          this.items,
          "provisioned_expenses"
        ),
        provisioned_total: this.findAmount(this.items, "provisioned_total"),
        balance: this.findAmount(this.items, "balance"),
      };
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    ...mapActions({
      searchBills: types.SEARCH_EXPECTED_BILLS,
    }),
    search() {
      this.loading.table = true;
      this.searchBills()
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao realizar a busca. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    findAmount(objs, key) {
      if (typeof key == "object") {
        var total = 0;
        _.forEach(key, function (value) {
          total += _.sumBy(objs, value) || 0;
        });
        return total;
      }
      return _.sumBy(objs, key) || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~flatpickr/dist/plugins/monthSelect/style.css";
</style>
