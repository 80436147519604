<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts
        v-if="data"
        type="line"
        height="300"
        :options="options"
        :series="chartData.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { monthYearAbbrev, toCurrency } from '@/helpers/converters'

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props:{
    data: [Array, Object]
  },
  computed:{
    chartData: function() {
      return {
        dates: _.map(this.data, (e) =>{ return monthYearAbbrev(e.date) }),
        series: [
          {
            name:'Previsão de receita',
            data: _.map(this.data, 'provisioned_payments')
          },
          {
            name:'Previsão comissão',
            data: _.map(this.data, 'provisioned_commissions')
          },
          {
            name:'Previsão interno futuro',
            data: _.map(this.data, 'provisioned_employee_dispenses')
          },
        ]
      }
    },
    options: function(){
      return {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: 'top'
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: ["#FFC107"],
          colors: ["#247BA0", "#FF1654", "#FFC107"],
        },
        colors: ["#247BA0", "#FF1654", "#FFC107"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
              toCurrency(data.series[data.seriesIndex][data.dataPointIndex])
            }</span></div>`
          },
          followCursor: true
        },
        xaxis: {
          categories:this.chartData.dates,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return toCurrency(value) ;
            }
          },
        },
      }
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
