<template>
  <div
    id="user-profile"
    class="profile"
  >
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- content -->
        <b-col
          cols="12"
        >
          <!-- navigation -->
          <div class="nav-bar">
            <div class="d-flex align-items-center">
              <h2> Contas a pagar </h2>
              <b-nav pills class="ml-4">
                <b-nav-item 
                  v-if="type == managmentTypes.PAYABLES && currentMonth === -1"
                  id="paid-payables"
                  :to="{name:'financial-management-payables'}"
                  :active="currentMonth == -1" 
                  class="profile-nav mr-1"
                >
                  {{ month | monthYear(year) }}
                </b-nav-item>
                <b-nav-item 
                  id="paids"
                  :to="{name:'financial-management-payables-paid'}"
                  :active="type == managmentTypes.PAID" 
                  class="profile-nav mr-1"
                >
                  PAGAS
                </b-nav-item>
                <b-nav-item 
                  id="payables"
                  :to="{name:'financial-management-payables'}"
                  :active="type == managmentTypes.PAYABLES && currentMonth === 1"  
                  class="profile-nav mr-1"
                >
                  A PAGAR
                </b-nav-item>
                <b-nav-item 
                  :to="{name:'financial-management-payables-expected'}"
                  :active="type == managmentTypes.EXPECTED"  
                  class="profile-nav mr-1"
                >
                  PREVISTAS
                </b-nav-item>
              </b-nav>
            </div>
          </div>
          <!-- navigation -->
          <financial-management-bills-list v-if="type == managmentTypes.PAYABLES" ref="payables" :year="year" :month="month" />
          <financial-management-paid-bills-list v-if="type == managmentTypes.PAID" ref="paids" />
          <financial-management-expected-bills-list v-if="type == managmentTypes.EXPECTED" ref="expected" />
        </b-col>
        <!-- content -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol, BNav, BNavItem, BSpinner } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import FinancialManagementBillsList from './FinancialManagementBillsList'
import FinancialManagementPaidBillsList from './FinancialManagementPaidBillsList'
import FinancialManagementExpectedBillsList from './FinancialManagementExpectedBillsList'
import * as types from '../store/types'
import * as sharedTypes from '@/modules/shared/store/types'
import * as managmentTypes from '@/constants/financial_management_types'



/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BNav,
    BNavItem,
    BSpinner,
    FinancialManagementBillsList,
    FinancialManagementPaidBillsList,
    FinancialManagementExpectedBillsList
  },
  props: {
    type: {
      type: String,
      default: managmentTypes.PAYABLES,
    },
  },
  setup(){
    return { toast: useToast() }
  },
  data() {
    return {
      managmentTypes,
      navigation:  undefined,
      presentBillingMonthAndYear: undefined
    }
  },
  computed:{
    ...mapGetters({
      activeBillingMonth: types.ACTIVE_BILLING_MONTH
    }), 
    year: function(){
      if(this.$route.params.year){
        return this.$route.params.year
      }
      return this.activeBillingMonth?.year || undefined
    },
    month: function(){
      if(this.$route.params.month){
        return this.$route.params.month
      }
      return this.activeBillingMonth?.month || undefined
    },
    currentMonth: function(){
      let currentYear = new Date().getFullYear()
      let currentMonth = new Date().getMonth() + 1
      if(!this.$route.params.month 
        && !this.$route.params.year 
      ){
        return 1
      }else if(
        this.year > currentYear || 
        (this.year == currentYear && this.month > currentMonth)
      ){
        return 2
      }
      return -1
    }
  },
  created(){
    this.getActiveBillingMonth()
      .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao obter o dia do faturamento. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  },
  methods: {
    ...mapActions({
      getActiveBillingMonth: types.GET_ACTIVE_BILLING_MONTH
    }),
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';


.dark-layout .profile-nav{
  background-color: $theme-dark-card-bg !important;
}
.profile-nav{
  width: 15rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #ffff;
}
</style>
