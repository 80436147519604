<template>
  <div>
    <b-card-header class="pb-50">
      <h5>
        Filtros
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6" class="mb-1">
          <b-form-group label="PN" label-for="searchConsultant">
            <v-select id="searchConsultant" multiple v-model="consultant" :reduce="consultant_list => consultant_list.id"
              :options="consultantList" :loading="loading.consultants" label="name" @keyup.enter="search">
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mb-1">
          <b-form-group label="Colaborador" label-for="searchPartner">
            <v-select id="searchPartner" multiple v-model="employee" :reduce="employee_list => employee_list.id"
              :options="employees" :loading="loading.employees" label="name" @keyup.enter="search">
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Franqueado" label-for="searchFranchise">
            <v-select id="searchFranchise" multiple v-model="franchise" :reduce="franchise_list => franchise_list.id"
              :options="franchises" :loading="loading.franchises" :close-on-select="false" label="name"
              @keyup.enter="search">
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Período" label-for="searchRangeDate">
            <flat-pickr id="searchRangeDate" v-model="rangeDate" class="form-control" :config="flatPickrConfig"
              :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Departamento" label-for="searchDepartment">
            <v-select id="searchDepartment" multiple v-model="department" :reduce="department_list => department_list.id"
              :options="departments" :loading="loading.departments" label="name" @change="updateCategories">
              <template #no-options="{ search, searching, loading }">Sem resultados</template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Categoria" label-for="searchCategory">
            <v-select id="searchCategory" v-model="category" multiple :reduce="category => category.id"
              :options="filteredCategories" :loading="loading.categories" label="name" @keyup.enter="search">
              <template #no-options="{ search, searching, loading }">Selecione um departamento</template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Tipo" label-for="searchCategory">
            <v-select id="searchType" v-model="type" :reduce="type => type.key" :options="withdrawalTypes"
              :loading="loading.withdrawalTypes" label="name" @keyup.enter="search">
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Status" label-for="searchStatuses">
            <v-select id="searchStatuses" v-model="status" :reduce="statuses => statuses.key"
              :options="withdrawalStatuses" :loading="loading.withdrawalStatuses" label="name" @keyup.enter="search">
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Nível" label-for="searchTeam">
            <v-select id="searchRole" multiple v-model="role" :reduce="(role_list) => role_list.id_consultant_role"
              :options="roles" :loading="loading.roles" :close-on-select="false" label="name" @input="consultant = []"
              @keyup.enter="search">
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Franquias Físicas" label-for="searchPhysicalFranchise">
            <v-select id="searchPhysicalFranchise" multiple v-model="physicalFranchise"
              :reduce="physical_franchise_list => physical_franchise_list.id" :options="physicalFranchises"
              :loading="loading.physicalFranchises" label="name" @keyup.enter="search">
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BFormInput, BFormDatepicker,
  BFormRadioGroup, BFormRadio, BButton, BSpinner, BPopover, BCardText
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import DynamicSelect from '@/modules/shared/components/DynamicSelect'
import VueSlider from 'vue-slider-component'
import * as types from '../store/types'
import * as sharedTypes from '@/modules/shared/store/types'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import { PENDING } from '@/constants/withdrawal_status'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BSpinner,
    vSelect,
    BCardText,
    BPopover,
    DynamicSelect,
    VueSlider,
    flatPickr
  },
  props: {
    year: [Number, String],
    month: [Number, String],
    search: Function
  },
  data() {
    return {
      loading: {
        consultants: false,
        employees: false,
        franchises: false,
        departments: false,
        withdrawalStatuses: false,
        withdrawalTypes: false,
        categories: false,
        roles: false,
        physicalFranchises: false
      },
      employee: undefined,
      consultant: undefined,
      status: PENDING,
      franchise: undefined,
      rangeDate: undefined,
      category: undefined,
      department: undefined,
      role: undefined,
      type: undefined,
      physicalFranchise: undefined
    }
  },
  watch: {
    rangeDate: function () {
      this.search()
    },
    department: function () {
      this.updateCategories()
    }
  },
  setup() {
    const actualYear = new Date().getFullYear()
    return { toast: useToast(), actualYear }
  },
  computed: {
    ...mapGetters({
      consultantList: sharedTypes.CONSULTANTS,
      franchises: sharedTypes.FRANCHISES,
      departments: sharedTypes.DEPARTMENTS,
      allCostCenterCategories: sharedTypes.ALL_COST_CENTER_CATEGORIES,
      employees: sharedTypes.EMPLOYEES,
      withdrawalStatuses: sharedTypes.WITHDRAWAL_STATUSES,
      withdrawalTypes: sharedTypes.WITHDRAWAL_CATEGORIES,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      roles: sharedTypes.ROLES,
      physicalFranchises: sharedTypes.PHYSICAL_FRANCHISES
    }),
    flatPickrConfig: function () {
      if (this.year && this.month) {
        const year = parseInt(this.year)
        const month = parseInt(this.month)
        const date = new Date(year, month + 1);
        this.rangeDate = year + '-' + month + '-1' + ' até ' + date.getFullYear() + '-' + date.getMonth() + '-1'
      }
      return {
        altFormat: 'j M Y',
        altInput: true,
        mode: 'range',
        dateFormat: 'Y-m-d',
        locale: Portuguese
      }
    },
    filteredCategories() {
      if (!this.department || this.department.length === 0) return [];
      return this.allCostCenterCategories.filter(category =>
        this.department.includes(category.cost_center_id)
      );
    }
  },
  mounted() {
    this.loading.consultants = true
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultants = false
      })
    this.loading.franchises = true
    this.getFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as franquias para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.franchises = false
      })
    this.loading.departments = true
    this.getDepartments()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os departamentos para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.departments = false
      })
    this.loading.categories = true
    this.getAllCostCenterCategories()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as categorias para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.categories = false
      })
    this.loading.employees = true
    this.getEmployees()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os colaboradores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.employees = false
      })
    this.loading.withdrawalStatuses = true
    this.getStatuses()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os status para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.withdrawalStatuses = false
      })
    this.loading.withdrawalTypes = true
    this.getwithdrawalTypes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as categorias para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.withdrawalTypes = false
      })
    this.loading.roles = true
    this.getRoles()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os níveis para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.roles = false
      })
    this.loading.physicalFranchises = true
    this.getPhysicalFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as franquias físicas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.physicalFranchises = false
      })
  },
  methods: {
    ...mapActions({
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getFranchises: sharedTypes.GET_FRANCHISES,
      getEmployees: sharedTypes.GET_EMPLOYEES,
      getDepartments: sharedTypes.GET_DEPARTMENTS,
      getAllCostCenterCategories: sharedTypes.GET_ALL_COST_CENTER_CATEGORIES,
      getStatuses: sharedTypes.GET_WITHDRAWAL_STATUSES,
      getwithdrawalTypes: sharedTypes.GET_WITHDRAWAL_CATEGORIES,
      getRoles: sharedTypes.GET_ROLES,
      getPhysicalFranchises: sharedTypes.GET_PHYSICAL_FRANCHISES
    }),
    updateCategories() {
      this.category = undefined;  // Reset category selection when departments change
    }
  }
}
</script>

<style lang="scss">
$themeColor: #f26237;
@import '~vue-slider-component/lib/theme/default.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.form-group {
  margin-bottom: 0;
}
</style>
